
import React from 'react'
import Logo from '../src/img/bhlogo.png'
import { motion } from 'framer-motion'

function App() {
  return (
    <section className='h-screen flex justify-center items-center text-white'>
      <div className='md:w-3/5 flex flex-col justify-center items-center'>
        <img src={Logo} alt='logo' className='w-[250px] h-auto' />
        <h1 className='text-4xl font-bold mt-5 mb-10'>Bienvenido a BH</h1>
        <motion.a
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className='my-5 bg-white hover:bg-gray text-2xl text-black font-bold py-2 px-4 rounded uppercase w-[250px] text-center' href='https://trademarket.com.mx'
          target='_blank'
        >
          Visitar BH
        </motion.a>
        <motion.a
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className='my-5 bg-white hover:bg-gray text-2xl text-black font-bold py-2 px-4 rounded uppercase w-[250px] text-center' href='https://forms.gle/2KDejUjX7BfZjaei8'
          target='_blank'
        >
          Aspirantes
        </motion.a>
      </div>
    </section>
  )
}

export default App
